import React from 'react';
import StickyFooter from '@/ui/shared/components/Landing/StickyFooter';

interface IProps {
  config: any,
  contentClassName: string,
}

const Footer = (props: IProps) => (
  <StickyFooter
    contentClassName={ props.contentClassName }
    config={ props.config }
  />
);

export default Footer;
